import classNames from 'classnames';
import * as style from './ScrollPanel.module.less';
import { useRef } from 'react';

interface IScrollPanelProps {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    id?: string;
}
export const ScrollPanel = (props: IScrollPanelProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const classes = classNames(style.scrollContainer, props.className);
    return (
        <div
            className={classes}
            style={props.style}
            id={props.id}
            ref={scrollRef}
            onMouseLeave={() => {
                if (scrollRef.current) {
                    scrollRef.current.style.paddingRight = '21px';
                    scrollRef.current.style.overflowY = 'hidden';
                }
            }}
            onMouseEnter={() => {
                if (scrollRef.current) {
                    scrollRef.current.style.paddingRight = '10px';
                    scrollRef.current.style.overflowY = 'scroll';
                }
            }}
        >
            {props.children}
        </div>
    );
};

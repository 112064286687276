import { useStore, useStoreUI } from '@assets/model/Store';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ScrollPanel } from '../Panel/ScrollPanel/ScrollPanel';
import { Flex } from '../UI/Flex/Flex';
import { ChatContainer } from './ChatContainer/ChatContainer';
import * as style from './ChatPage.module.less';
export const ChatPage = observer(() => {
    const [inputText, setInputValue] = useState('');
    const store = useStore();
    const ui = useStoreUI();
    const query = store.query;
    const history = store.reportChatHistory;
    const totalMessages = history.length;
    useEffect(() => {
        const chatScroll = document.getElementById('chat-scroll');
        if (chatScroll) {
            setTimeout(() => {
                chatScroll.scrollTop = chatScroll.scrollHeight;
            }, 50);
        }
    }, [totalMessages]);

    const refInput = React.useRef<HTMLInputElement>(null);
    const isRagAvail = query.ragAPI.isAvailable;

    const onClickSend = () => {
        if (ui.chatWaitingForResponse) return;

        const input = refInput.current.value;
        if (input.length == 0) return;

        const keys = Object.keys(store.reportChatHistory).sort();
        const msgsId = keys.slice(-4);
        const companyName = query.value('company_name');
        const shortDesc = query.value('company_overview_short');
        const date = new Date().toISOString();

        var context = ``;

        if (msgsId.length > 0) {
            context += 'Previous Messages: \r\n';
            context += msgsId
                .map(msgId => {
                    const m = store.reportChatHistory[msgId];
                    if (m.isMe) {
                        return `USER: ${m.message}`;
                    } else {
                        return `ASSISTANT: ${m.message}`;
                    }
                })
                .join('\r\n');
        }

        query.ragAPI.sendMessage(input, context);

        setInputValue('');
    };

    //So ugly
    const waitingForReply = (
        <i>
            <div
                className="spinner-border spinner-border-sm"
                role="status"
                style={{
                    animationDuration: '1.25s',
                    width: '20px',
                    height: '20px'
                }}
            ></div>{' '}
            Send
        </i>
    );

    const sendButtonContent = (
        <i
            className="bi bi-send"
            onClick={() => {
                onClickSend();
            }}
        >
            {' '}
            Send
        </i>
    );
    const sendButton = (
        <Button disabled={!isRagAvail} className={style.sendButton}>
            {ui.chatWaitingForResponse ? waitingForReply : sendButtonContent}
        </Button>
    );

    return (
        <div className={style.container}>
            <Flex direction="column" fillCell={true}>
                <div className={style.messages}>
                    <ScrollPanel
                        id="chat-scroll"
                        className={style.scrollFadeContent}
                    >
                        <ChatContainer chatId="1" />
                    </ScrollPanel>
                </div>
                <Flex className={style.inputLineOffset}>
                    <div className={style.input}>
                        <input
                            ref={refInput}
                            type="text"
                            value={inputText}
                            placeholder="Enter a question"
                            autoFocus={true}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onClickSend();
                                }
                            }}
                            onChange={e => {
                                setInputValue(e.currentTarget.value);
                            }}
                        ></input>
                    </div>
                    {sendButton}
                </Flex>
            </Flex>
        </div>
    );
});

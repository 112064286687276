import logo from '@assets/component/Header/logo.svg';
import new_logo from '@assets/component/Header/new_logo.png';
import { useNavUtils } from '@assets/utils/NavUtils';

export const Logo = () => {
    const nav = useNavUtils();
    return (
        <img
            style={{ cursor: 'pointer', objectFit: 'contain' }}
            src={new_logo}
            width={160}
            onClick={() => {
                nav.home();
            }}
        />
    );
};

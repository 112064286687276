//Get yearly value
//get Value
//default values
//Sources 

import { FieldsSchema } from '@assets/model/FieldsSchema';
import { getFloat } from '@assets/utils/Formatting';
import { get_numbers } from '@assets/utils/Utils';
import assert from 'assert';
import _ from 'lodash';
import { Field, PanelColumn, PanelRow } from '../Panel/PanelTable/PanelTable';
import scoreDefinition from './ScoreDefintion.json';
import { RagAPI } from '@assets/model/RagAPI';

export class DataAccessUtils {
	private report: any;
	private _scoreCache: number = null;

	constructor(reportOriginal: any, private overrides: any, public ragAPI: RagAPI = null) {

		if (this.ragAPI) {
			this.ragAPI.init(this);
		}

		this.report = _.cloneDeep(reportOriginal);
		if (!this.report) {
			return null;
		}

		var over_rides = overrides;
		for (var field in over_rides) {
			const value = over_rides[field];
			//typeof a == 'object' && Array.isArray(a)\
			//Connect to field type in schmea to know sub field
			const fieldsSchema = FieldsSchema;

			if (typeof value == 'object') {
				if (!Array.isArray(value)) {
					var set = value as {};

					if (!Array.isArray(this.report[field])) {
						this.report[field] = [];
					}

					const keys = Object.keys(set);
					keys.forEach(year => {
						const yearValue = set[year];
						assert(
							fieldsSchema.hasOwnProperty(field),
							`Field ${field} not found in schema`
						);
						const subFieldName = fieldsSchema[field].field;

						const e = this.report[field].find(arrYear => {
							return parseInt(arrYear.year) == parseInt(year);
						});

						const entry = {
							year: parseInt(year),
							[subFieldName]: yearValue
						};

						if (!e) {
							this.report[field].push(entry);
						} else {
							e[subFieldName] = yearValue;
						}
					});
				} else {
					//Array TODO
				}
			} else {
				this.report[field] = value;
			}
		}
	}

	isValueEditedByRowCol(row: PanelRow, col: PanelColumn) {
		const schema = FieldsSchema;
		const edits = this.overrides;

		const f = schema[row.field.source];

		if (f.yearly) {
			if (!edits.hasOwnProperty(row.field.source)) {
				return false;
			}
			if (!edits[row.field.source].hasOwnProperty(col.column.source.toString())) {
				return false;
			}

			return true;

		} else { //value
			return edits.hasOwnProperty(row.field.source);
		}
	}

	isValueEditedAtAll(fieldName: string) {
		const edits = this.overrides;
		return edits.hasOwnProperty(fieldName);
	}

	get activeReport() {
		return this.report;
	}

	value(fieldName: string) {
		let data = this.report;
		if (!data) return 'N/A';

		if (data.hasOwnProperty(fieldName)) {
			var field = FieldsSchema[fieldName];
			var val = data[fieldName];
			if (field && field.t == 'bool') {
				if (typeof val === 'string') {
					return val === 'true' ? true : false;
				}
			}
			return data[fieldName];
		}

		return 'N/A';
	}


	actualYear() {
		const actualizedYears = this.report['list_years_actualized_revenue'] ?? [];
		return Math.max(...actualizedYears);
	}

	valueByYearDivideByRevenue(fieldName: string, year: number, yearOffset: number = 0) {
		let data = this.report;
		if (!data) {
			return 'N/A';
		}
		try {
			const fieldValue = this.valueByYear(fieldName, year, yearOffset);
			const revenue = this.valueByYear('net_revenue_per_year', year, yearOffset);
			return fieldValue / revenue;
		} catch {
			return 'N/A';
		}
	}

	valueByYear(fieldName: string, year: number, yearOffset: number = 0) {
		let data = this.report;
		if (!data) {
			return 'N/A';
		}

		const fieldInfo = FieldsSchema[fieldName];
		const valueField = fieldInfo.field;

		const yearSource = year + yearOffset;
		const dataRow = data[fieldName];

		if (!dataRow || !Array.isArray(dataRow)) {
			return 'N/A';
		}

		const r = dataRow.find((d: any) => parseInt(d.year) === yearSource);
		if (r == undefined) {
			return 'N/A';
		}

		if (r.hasOwnProperty(valueField)) {
			return r[valueField];
		} else {
			return 'N/A';
		}
	}

	organicCagr(howManYears: number) {
		try {
			const q = this;
			const lastYear = q.actualYear();
			const current = q.valueByYear(
				'net_revenue_per_year',
				lastYear
			);
			const before = q.valueByYear(
				'net_revenue_per_year',
				lastYear,
				-howManYears
			);

			if (current == 'N/A' || before == 'N/A') {
				return 'N/A';
			}
			return (current / before) ** (1 / howManYears) - 1;
		} catch {
			return 'N/A';
		}
	}

	//Merge the two
	projectedCagr(howManYears: number) {
		try {
			const q = this;
			const lastYear = q.actualYear();
			const current = q.valueByYear(
				'net_revenue_per_year',
				lastYear,
			);
			const projected = q.valueByYear(
				'net_revenue_per_year',
				lastYear,
				howManYears
			);

			if (current == 'N/A' || projected == 'N/A') {
				return 'N/A';
			}
			return (projected / current) ** (1 / howManYears) - 1;
		} catch (e) {
			return 'N/A';
		}
	}


	ebitdaMargin(year: number) {
		const ebitda = this.valueByYear('ebitda_per_year', year);
		const revenue = this.valueByYear('net_revenue_per_year', year);

		if (ebitda === 'N/A' || revenue === 'N/A') {
			return 'N/A';
		}

		return ebitda / revenue;
	}

	fcf_conversion_ebitda(year: number) {
		const ebitda = this.valueByYear(
			'ebitda_per_year',
			year
		);

		const capex = this.valueByYear('capex_per_year', year);

		if (ebitda === 'N/A' || capex === 'N/A') {
			return 'N/A';
		}

		return (ebitda - capex) / ebitda;
	}

	get_extended_value(fieldName: string) {
		const val = this.get_extended_value_internal(fieldName);
		if (val === 'N/A' || isNaN(val) || val === '' || val === 'NaN') {
			return 'N/A';
		}

		return getFloat(val);
	}

	get_extended_value_internal(fieldName: string) {
		switch (fieldName) {
			case "organic_cagr_4_years":
				return this.organicCagr(4);
			case "organic_cagr_3_years":
				return this.organicCagr(3);
			case "projected_organic_cagr_3_years":
				return this.projectedCagr(3);
			case "projected_organic_cagr_4_years":
				return this.projectedCagr(4);
			case "projected_organic_cagr_4_years_minus_tam_growth":
				var projected = this.projectedCagr(4);
				var market_growth = this.value('historical_market_growth');
				if (projected === 'N/A' || market_growth === 'N/A') {
					return 'N/A';
				}
				return (projected as number) - (market_growth as number);

			case "ebitda_margin":
				return this.ebitdaMargin(this.actualYear());
			case "ebitda_margin_trend":
				return this.ebitdaMargin(this.actualYear());
			case "ebitda":
				return this.valueByYear('ebitda_per_year', this.actualYear());
			case "gross_retention":
				return this.valueByYearDivideByRevenue(
					'gross_retention_lost_only_per_year',
					this.actualYear()
				);
			case "net_retention":
				return this.valueByYear(
					'net_retention_yearly',
					this.actualYear()
				);
			case "recurring_revenue_summary":
				return this.value('recurring_revenue_summary');

			case "ebitda_margin_2_year_change":
				const ebitdaMargin = this.ebitdaMargin(this.actualYear());
				const ebitdaMargin2YearsAgo = this.ebitdaMargin(this.actualYear() - 2);
				if (ebitdaMargin === 'N/A' || ebitdaMargin2YearsAgo === 'N/A') {
					return 'N/A';
				}

				return ebitdaMargin - ebitdaMargin2YearsAgo;
			case "ebitda_adjustment_magnitude":
				const ebitda = this.valueByYear('ebitda_per_year', this.actualYear());
				const adj_ebitda = this.valueByYear('adjusted_ebitda_per_year', this.actualYear());
				return (adj_ebitda - ebitda) / adj_ebitda
			case "fcf_conversion_ebitda":
				return this.fcf_conversion_ebitda(this.actualYear());
			case "nwc_as_percent_of_revenue":
				const currentAssets = this.valueByYear('total_current_assets_yearly', this.actualYear());
				const currentLiabilities = this.valueByYear('total_current_liabilities_yearly', this.actualYear());
				const revenue = this.valueByYear('net_revenue_per_year', this.actualYear());
				if (currentAssets === 'N/A' || currentLiabilities === 'N/A' || revenue === 'N/A') {
					return 'N/A';
				}
				return (currentAssets - currentLiabilities) / revenue;
			case "free_cash_flow_conversion":
				const ebitdaCashFlow = this.valueByYear('ebitda_per_year', this.actualYear());
				const maintenance_capex = this.valueByYear('maintenance_capex_per_year', this.actualYear());
				if (ebitdaCashFlow === 'N/A' || maintenance_capex === 'N/A') {
					return 'N/A';
				}

				return (ebitdaCashFlow - maintenance_capex) / ebitdaCashFlow;
			case "total_capex_by_rev":
				const capex = this.valueByYear('capex_per_year', this.actualYear());
				const revenueCapex = this.valueByYear('net_revenue_per_year', this.actualYear());
				if (capex === 'N/A' || revenueCapex === 'N/A') {
					return 'N/A';
				}
				return capex / revenueCapex;

			case "total_addressable_market":
			case "historical_market_growth":
			case "projected_market_growth":
				return this.value(fieldName);
		}
	}

	do_score_calc(score: any) {
		const p = score.points;
		var fieldValue = this.get_extended_value(score.value);
		var ret = 0;
		switch (score.check) {
			case "greater_than":
				ret = fieldValue > score.threshold ? p : 0;
				this.log(`Scoring -> (${score.value}) greater_than (${score.threshold}): ${fieldValue}: ${ret}/${p}`);
				break;
			case "range":
				const [low, high] = score.range;
				var norm = ((fieldValue as number) - low) / (high - low);
				norm = Math.min(1, Math.max(0, norm));
				if (score.inverse_score === true) {
					this.log(`*** Inverse Scoring (${score.value}): ${norm} to: ${1 - norm} ***`);
					norm = 1 - norm;
				}

				ret = p * norm;
				this.log(`Scoring -> (${score.value}) range[${low}, ${high}] (Value: ${(fieldValue as number).toFixed(2)}, Norm: ${norm.toFixed(2)}): ${ret}/${p}`);
				break;
			default:
				this.log("Scoring-> Unknown check type: " + score.check);
				ret = 0;
				break;
		}

		if (isNaN(ret)) {
			this.log("Scoring-> Error calculating score for " + score.value);
			ret = 0;
		}

		return ret;
	}

	log(msg: string) {
		const url = window.location.href;
		if (url[url.length - 1] != '/') {
			console.log(msg);
		}
	}

	count_for_scoring(score: any) {
		var value = this.get_extended_value(score.value);
		if (value == 'N/A' || isNaN(value as number)) {
			this.log("Scoring-> Skipping: " + score.value + " due to N/A or NaN");
			return false;
		}

		return true;
	}

	score() {
		if (this._scoreCache != null) {
			return this._scoreCache;
		}
		const scoreDef = scoreDefinition;
		var totalPointsAvailable = 0;
		var totalPoints = 0.0;
		for (const score of scoreDef) {
			const p = score.points;
			if (this.count_for_scoring(score)) {
				totalPointsAvailable += p;
				var scoreGiven = this.do_score_calc(score);
				totalPoints += scoreGiven;
			}
		}

		this.log(`Scoring-> Total Points: ${totalPoints.toFixed(2)}/${totalPointsAvailable.toFixed(2)}`);


		if (totalPointsAvailable == 0) {
			this._scoreCache = -1;
		} else {
			this._scoreCache = Math.round(totalPoints / totalPointsAvailable * 100);
		}

		return this._scoreCache;
	}

	sourcesByField(fieldName: string,) {
		let fields = [];

		//This is not allowing to update the contractual rev sources - for now edit out
		// if (fieldName === 'recurring_revenue_summary') {
		// 	fields = [
		// 		'subscription_revenue',
		// 		'recurring_revenue_stream',`
		// 		'contractual_revenue'
		// 	];
		// } else 		
		fields.push(fieldName);

		const conv_source_to_num_array = (sourcesValue: any) => {
			var pages = get_numbers(sourcesValue.toString());
			return pages;
		};

		var sources = [];
		fields.forEach(fieldEntry => {
			let val = this.value(fieldEntry + '_page_source');
			sources = sources.concat(
				conv_source_to_num_array(val)
			);
		});

		return sources;
	}

	sources(field: Field, column: Field) {
		let l = this.sourcesByField(field.source);
		l = l.sort((a, b) => a - b);
		return _.uniq(l)
	}
}
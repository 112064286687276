import assert from 'assert';
import React, { ReactElement } from 'react';
import { Flex } from '../UI/Flex/Flex';
import Draggable from 'react-draggable';

interface ISplitProps {
    children: React.ReactNode;
    layoutPercent: number[];
    className?: string;
    style?: React.CSSProperties;

    childClass?: string;
}

export const Split = (props: ISplitProps) => {
    const children = React.Children.toArray(props.children);
    assert(
        children.length === props.layoutPercent.length,
        'Children and size must have the same length'
    );

    const cls = props.className || '';
    const style = props.style || {};
    style.position = 'relative';

    const display = children.map((child, index) => {
        let size = props.layoutPercent[index];
        const childClass = props.childClass || '';
        return (
            <div
                key={index}
                className={childClass}
                style={{ width: `${size}%`, height: '100%' }}
            >
                {child}
            </div>
        );
    });

    return (
        <>
            <Flex className={cls} style={style} fillCell={true}>
                {display}
            </Flex>
        </>
    );
};

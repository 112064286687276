import { useStore, useStoreUI } from '@assets/model/Store';
import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { DealScore } from '../DealDetails/DealScore/DealScore';
import { Flex } from '../UI/Flex/Flex';
import * as style from './Header.module.less';
import { Logo } from './Logo';
import { UploadDeal } from '../UploadDeal/UploadDeal';
import classNames from 'classnames';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';

interface IHeader {
    disableTicker?: boolean;
    showDealDbLink?: boolean;
    showScore?: boolean;
    showNewDeal?: boolean;
    showShadow?: boolean;
}

export const Header = observer((p: IHeader) => {
    const store = useStore();
    const storeUI = useStoreUI();
    const navUtils = useNavUtils();
    const q = store.query;
    const useShadow = p.showShadow ?? true;
    const cls = classNames(style.container, { [style.shadow]: useShadow });
    const clientName = getFirebase().getConfig().clientName;
    return (
        <Flex direction="column" className={cls} justify="center">
            <Flex style={{ paddingBottom: '5px' }}>
                <Logo />
                <div style={{ width: '30px' }}></div>
                {p.showDealDbLink && (
                    <Button
                        variant="light"
                        onClick={() => {
                            navUtils.home();
                        }}
                    >
                        Deal Database
                    </Button>
                )}
                <div className="flex-grow-1"></div>
                <div className={style.clientName}>
                    {clientName.toUpperCase()}
                </div>
            </Flex>

            <Flex
                direction="row"
                alignItems="center"
                className={style.headerRow}
                justify="between"
            >
                <div className={style.title}>{storeUI.pageTitle}</div>
                {p.showScore && q && (
                    <DealScore
                        score={q.score()}
                        className={style.scorePosition}
                    />
                )}

                <div className="flex-grow-1"></div>
                {p.showNewDeal && <UploadDeal />}
            </Flex>
        </Flex>
    );
});

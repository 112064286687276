import * as gstyle from '../../utils/global.module.less';
import { Panel } from '../Panel/Panel';
import { Split } from '../Split/Split';
import { Flex } from '../UI/Flex/Flex';
import { DealDetailsHeader } from './DealDetailsHeader/DealDetailsHeader';
import * as style from './DealDetails.module.less';
import { throttle } from 'lodash';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { useSetPageTitle, useStore, useStoreUI } from '@assets/model/Store';
import { useNavUtils } from '@assets/utils/NavUtils';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { ChatPage } from '../ChatPage/ChatPage';
import { PanelCompanyFinancials } from '../Financial/PanelCompanyYearly/PanelCompanyFinancials';
import { PanelIndustry } from '../Financial/PanelIndustry/PanelIndustry';
import { PanelCompanyOverview } from '../Panel/PanelCompanyOverview/PanelCompanyOverview';
import { ScrollPanel } from '../Panel/ScrollPanel/ScrollPanel';
import dragIcon from './dragIcon.svg';
import { useSpringValue } from '@react-spring/web';
export const DealDetails = observer(() => {
    const pdfRef = useRef(null);
    const store = useStore();
    const nav = useNavUtils();
    const uiStore = useStoreUI();

    const [highlightDrag, setHighlightDrag] = useState(false);

    const [contentSize, setContentSize] = useState(
        uiStore.currentSplitRatioDetailView
    );

    const setContentSizeThrottle = useCallback(
        throttle(size => {
            setContentSize(size);
        }, 50),
        []
    );
    const [dragPosX, setDragPosX] = useState(0);
    const [winWidth, setWindowWidth] = useState(0);
    const [pdfViewer, setPdfViewer] = useState(null);

    const repositionDragable = () => {
        setDragPosX(winWidth * (contentSize / 100));
    };

    const resizeRefCallback = useRef(() => {});
    resizeRefCallback.current = repositionDragable;

    const draggableSpring = useSpringValue(0, {
        onChange: val => {
            const ui = useStoreUI();
            setContentSize(val as any);
        },
        onRest: val => {
            resizeRefCallback.current();
        }
    });

    useEffect(() => {
        uiStore.currentSplitRatioDetailView = contentSize;
    }, [contentSize]);

    useEffect(
        function hideShowPdfViewer() {
            if (pdfRef.current == null) return;
            const isPdfView = uiStore.sideContent === 'PDF';
            pdfRef.current.style.visibility = isPdfView ? 'visible' : 'hidden';
        },
        [pdfRef.current, uiStore.sideContent]
    );

    useEffect(
        function auditReportView() {
            getFirebase().store_audit_entry('VIEW_PAGE_DEAL', {
                page: 'DealView',
                deal_id: store.uiDisplayReportId,
                url: window.location.href
            });
        },
        [store.uiDisplayReportId]
    );

    useEffect(
        function handleResize() {
            const onResize = () => {
                const contWidth = document
                    .getElementById('content_container')
                    .getBoundingClientRect().width;

                setWindowWidth(contWidth);
            };

            window.addEventListener('resize', onResize);

            const initTimeout = () => {
                setTimeout(() => {
                    if (document.getElementById('content_container')) {
                        onResize();
                    } else {
                        initTimeout();
                    }
                }, 50);
            };

            initTimeout();
            return () => {
                window.removeEventListener('resize', onResize);
            };
        },
        [uiStore.dealViewTab]
    );

    useEffect(() => {
        repositionDragable();
    }, [winWidth]);

    //Refactor into component
    useEffect(
        function setupAdobeViewer() {
            // @ts-ignore
            if (!window.AdobeDC) {
                return;
            }

            if (pdfRef.current == null) {
                return;
            }

            const pdfFile: string = toJS(store.pdfFileUrl);
            const companyName: string = toJS(store.company_name);

            if (!pdfFile || pdfFile == '') {
                return;
            }

            //LOCALHOST KEY 95c70d4882b846f7899dbcd35e0ce896
            const isLocalhost = window.location.href.indexOf('localhost') > -1;
            var prod_key_harvest = '95c70d4882b846f7899dbcd35e0ce896';
            var prod_key_odyssey = '677c18f9b10f46dc9a3380def61a81ab';
            const prod_key =
                window.location.href.indexOf('flightlinelab.com') > -1
                    ? prod_key_harvest
                    : prod_key_odyssey;

            var localhost_key = '2042181d883b4bddaabbc5930d48b302';
            const pdf_key = isLocalhost ? localhost_key : prod_key;
            // @ts-ignore
            var adobeDCView = new AdobeDC.View({
                clientId: pdf_key,
                divId: 'adobe-dc-view'
            });

            var previewFilePromise = adobeDCView.previewFile(
                {
                    content: {
                        location: {
                            url: pdfFile
                        }
                    },
                    metaData: { fileName: companyName }
                },
                {
                    embedMode: 'FULL_WINDOW',
                    enableFormFilling: false,
                    showAnnotationTools: false,
                    showZoomControl: true,
                    defaultViewMode: 'FIT_PAGE'
                }
            );

            previewFilePromise.then(adobeViewer => {
                setPdfViewer(adobeViewer);
            });
        },
        [pdfRef, store.pdfFileUrl]
    );

    useEffect(
        function updatePdfViewerPage() {
            if (store.pdfImagePage != -1 && pdfViewer != null) {
                pdfViewer.getAPIs().then(apis => {
                    apis.gotoLocation(store.pdfImagePage, 0, 0)
                        .then(result => console.log(result))
                        .catch(error => console.log(error));
                });
            }
        },
        [store.pdfImagePage]
    );

    useSetPageTitle(store.query?.value('company_name') ?? '');

    const getCompanyData = (reportId: string) => {
        const q = store.createDataAccess(reportId);
        const year = q.actualYear();
        const company_overview = q.value('company_overview');
        const company_overview_short = q.value('company_overview_short');
        const industry = q.value('industry');
        const subIndustry = q.value('industrySubType');

        const ebitda = q.valueByYear('ebitda_per_year', year);
        const ebitdaMargin = q.valueByYearDivideByRevenue(
            'ebitda_per_year',
            year
        );

        const netRevenue = q.valueByYear('net_revenue_per_year', year);
        const grossProfit = q.valueByYear('gross_profit_per_year', year);

        const ebitdaMargin2YearsAgo = q.valueByYearDivideByRevenue(
            'ebitda_per_year',
            year,
            -2
        );

        const adjustedEbidta = q.valueByYear('adjusted_ebitda_per_year', year);

        const totalCapexByRev = q.valueByYearDivideByRevenue(
            'capex_per_year',
            year
        );

        const gross_retention = q.valueByYearDivideByRevenue(
            'gross_retention_lost_only_per_year',
            year
        );

        const netRet = q.valueByYear('net_retention_yearly', year);

        const capexPerYear = q.valueByYear('capex_per_year', year);
        const maintenanceCapexPerYear = q.valueByYear(
            'maintenance_capex_per_year',
            year
        );

        const ebitdaCashflow = q.valueByYear('ebitda_cashflow_yearly', year);

        const totalAssets = q.valueByYear('total_current_assets_yearly', year);
        const totalLiabilities = q.valueByYear(
            'total_current_liabilities_yearly',
            year
        );

        const tam = q.value('total_addressable_market');
        const historicalMarketGrowth = q.value('historical_market_growth');
        const projectedMarketGrowth = q.value('projected_market_growth');
        const contractualRevenue = q.value('recurring_revenue_summary');

        const rows = [];
        const data = {};

        const addValue = (name, value) => {
            data[name] = value;
            //rows.push(`${name},${value}`);
        };

        addValue('company_name', q.value('company_name'));
        addValue('company_overview', company_overview);
        addValue('company_overview_short', company_overview_short);
        addValue('industry', industry);
        addValue('sub_industry', subIndustry);
        addValue('net_revenue', netRevenue);
        addValue('gross_profit', grossProfit);
        addValue('organic_cagr_2_years', q.organicCagr(2));
        addValue('projected_organic_cagr_2_years', q.projectedCagr(2));
        addValue('organic_cagr_3_years', q.organicCagr(3));
        addValue('projected_organic_cagr_3_years', q.projectedCagr(3));
        addValue('organic_cagr_4_years', q.organicCagr(4));
        addValue('projected_organic_cagr_4_years', q.projectedCagr(4));
        addValue('organic_cagr_5_years', q.organicCagr(5));
        addValue('projected_organic_cagr_5_years', q.projectedCagr(5));
        addValue('ebitda', ebitda);
        addValue('ebitda_margin', ebitdaMargin);
        addValue('ebitda_margin_2_years_ago', ebitdaMargin2YearsAgo);
        addValue('adjusted_ebitda', adjustedEbidta);
        addValue('total_capex_by_rev', totalCapexByRev);
        addValue('gross_retention', gross_retention);
        addValue('net_retention', netRet);
        addValue('capex_per_year', capexPerYear);
        addValue('maintenance_capex_per_year', maintenanceCapexPerYear);
        addValue('free_cash_flow', ebitdaCashflow);
        addValue('total_assets', totalAssets);
        addValue('total_liabilities', totalLiabilities);
        addValue('tam', tam);
        addValue('historical_market_growth', historicalMarketGrowth);
        addValue('projected_market_growth', projectedMarketGrowth);
        addValue('contractual_revenue', contractualRevenue);

        return data;
    };

    function jsonToTransposedCSV(jsonData) {
        const companyIds = Object.keys(jsonData);
        const fieldSet = new Set();

        // Collect all unique fields
        companyIds.forEach(id => {
            Object.keys(jsonData[id]).forEach(field => fieldSet.add(field));
        });
        companyIds.sort();

        const fields = Array.from(fieldSet);

        // CSV Header
        let csv = `Field||${companyIds.join('||')}\n`;

        // Add rows for each field
        fields.forEach((field: string) => {
            const row = [field];
            companyIds.forEach(id => {
                row.push(
                    jsonData[id][field] !== undefined ? jsonData[id][field] : ''
                );
            });
            csv += row.join('||') + '\r\n';
        });

        return csv;
    }

    const genAllCompaniesData = () => {
        const companies = Object.keys(store.reportDataDB);
        const data = {};
        companies.sort();

        companies.forEach((company, i) => {
            data[company] = getCompanyData(company);
        });

        var csv = jsonToTransposedCSV(data);
        navigator.clipboard.writeText(csv);
    };

    if (!store.query) {
        return null;
    }

    const size = [contentSize, 100 - contentSize];

    return (
        <>
            {winWidth > 0 && (
                <Draggable
                    axis="x"
                    handle=".handle"
                    bounds={{ left: 200, right: winWidth - 200 }}
                    positionOffset={{ x: -5, y: 50 }}
                    // position={{
                    //     x: dragPos,
                    //     y: 0
                    // }}
                    //position={{ x: dragPosX, y: 0 }}
                    position={{
                        x: dragPosX,
                        y: 0
                    }}
                    onDrag={(e, data) => {
                        document.getElementById(
                            'adobe-dc-view'
                        ).style.pointerEvents = 'none';
                        var x = data.x;
                        var mouseX = data.lastX;
                        var normalized = data.x / winWidth;
                        console.log(
                            'DRAGGING: ' + x + ' ' + mouseX + ' ' + winWidth
                        );
                        const viewSize = normalized * 100;

                        setContentSizeThrottle(viewSize);
                    }}
                    onStop={(e, data) => {
                        //console.log('STOP DRAG: ', data.x);
                        var normalized = data.x / winWidth;
                        const viewSize = normalized * 100;
                        setContentSize(viewSize);
                        repositionDragable();
                        document.getElementById(
                            'adobe-dc-view'
                        ).style.pointerEvents = 'auto';
                    }}
                >
                    <div
                        className={'handle ' + style.draggable}
                        style={{
                            zIndex: 60000,
                            position: 'absolute',
                            height: 'calc(100vh - 220px)'
                        }}
                        onMouseEnter={() => {
                            setHighlightDrag(true);
                        }}
                        onMouseLeave={() => {
                            setHighlightDrag(false);
                        }}
                        onDoubleClick={() => {
                            const ui = useStoreUI();
                            if (80 - contentSize < 1) {
                                draggableSpring.set(contentSize).start(50);
                            } else {
                                draggableSpring.set(contentSize).start(80);
                            }
                        }}
                    >
                        <img
                            src={dragIcon}
                            style={{
                                pointerEvents: 'none',
                                transform: 'scale(0.9)',
                                opacity: 0
                            }}
                        ></img>
                    </div>
                </Draggable>
            )}
            <Flex direction="column" id="content_container" fillCell={true}>
                <DealDetailsHeader />
                <Split layoutPercent={size}>
                    <Flex direction="column">
                        <ScrollPanel>
                            <PanelCompanyOverview />
                            <PanelCompanyFinancials />
                            <PanelIndustry />
                        </ScrollPanel>
                    </Flex>

                    <Flex fillCell={true}>
                        <Panel shadow={true}>
                            {uiStore.sideContent === 'CHAT' ? (
                                <ChatPage />
                            ) : null}

                            <img
                                src={dragIcon}
                                className={style.dragIconStyle}
                                style={{
                                    filter: highlightDrag
                                        ? 'brightness(95%)'
                                        : 'none'
                                }}
                            ></img>
                            <div
                                ref={pdfRef}
                                id="adobe-dc-view"
                                style={{
                                    zIndex: 6000,
                                    borderRadius: '20px'
                                }}
                            ></div>
                        </Panel>
                    </Flex>
                </Split>
            </Flex>

            {store.showDeleteButton && (
                <input
                    type="button"
                    value="Delete"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '150px',
                        zIndex: 100000
                    }}
                    onClick={() => {
                        alert('This action will delete this report!');
                        var areYouSure = window.confirm(
                            'Are you sure you want to delete this report?' +
                                '\r\nReport Name:' +
                                store.uiDisplayReportId +
                                '\r\nCompany: ' +
                                store.query.value('company_name')
                        );
                        if (areYouSure) {
                            const fb = getFirebase();
                            fb.deleteReport(store.uiDisplayReportId);
                            nav.home();
                        }
                    }}
                />
            )}

            {store.showRerunReport && (
                <input
                    type="button"
                    value="Rerun"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '100px',
                        zIndex: 100000
                    }}
                    onClick={() => {
                        var areYouSure = window.confirm(
                            '** RERUN: Are you sure ?' +
                                '\r\nReport Name:' +
                                store.uiDisplayReportId +
                                '\r\nCompany: ' +
                                store.query.value('company_name')
                        );

                        if (areYouSure) {
                            const fb = getFirebase();
                            const pdfFile = store.query.value('pdf');
                            //alert(pdfFile);
                            var clearSummary = window.confirm(
                                'Should I clear summary cachces? OK to clear, Cancel to keep.'
                            );

                            fb.add_job(pdfFile, null, null, clearSummary);
                            nav.home();
                        }
                    }}
                />
            )}

            {store.showExportButton && (
                <input
                    type="button"
                    value="Export"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '50px',
                        zIndex: 100000
                    }}
                    onClick={() => {
                        genAllCompaniesData();
                    }}
                />
            )}
        </>
    );
});

import { ActiveJob } from '@assets/component/ActiveJob/ActiveJob';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import DealDBPage from './DealDBPage';
import DealView from './DealView';
import LoginPage from './Login';
import { Loading } from '@assets/component/Loading/Loading';
export const PageFrame = observer(() => {
    const store = useStore();

    //Improve conditoinal loading routing
    return (
        <>
            <ToastContainer
                position="bottom-right"
                style={{ zIndex: 100000000 }}
            />
            <ActiveJob />

            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={store.isLoaded ? <DealDBPage /> : <Loading />}
                    />
                    <Route
                        path="/db"
                        element={store.isLoaded ? <DealDBPage /> : <Loading />}
                    />
                    {/* <Route path="/chat" element={<ChatUI />} /> */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/:id"
                        element={store.isLoaded ? <DealView /> : <Loading />}
                    />
                </Routes>
            </Router>
        </>
    );
});

import moment from 'moment';
import { ChatMessageData } from '../ChatPage/ChatContainer/ChatContainer';
import { Flex } from '../UI/Flex/Flex';
import * as style from './ChatMessage.module.less';

import biz_man from './biz-man.svg';
import search from './search.svg';

const documents = require('./documents.csv');
const embed = require('./embedded.csv');

const embed_id_to_doc_id = (embed_id: number) => {
    const doc_id = embed.filter((e: any) => e[0] === embed_id.toString());

    if (doc_id.length === 0) {
        return -1;
    } else {
        return doc_id[0][1];
    }
};

const doc_id_to_doc_name = (doc_id: number) => {
    const doc_name = documents.filter((d: any) => d[0] === doc_id.toString());

    if (doc_name.length === 0) {
        return '';
    } else {
        return doc_name[0][1];
    }
};

interface ChatMessageProps {
    msg: ChatMessageData;
}

export const ChatMessage = (p: ChatMessageProps) => {
    const files_references = null;
    const mFormat = 'MM/DD/YYYY HH:mm';
    const msg = p.msg;
    if (msg.isMe) {
        return (
            <>
                <Flex className={style.border} direction="row">
                    <div className="p-4" style={{ width: '100px' }}>
                        {moment(msg.date).format(mFormat)}
                    </div>
                    <div
                        className="flex-grow-1 p-4 align-self-center"
                        style={{ textAlign: 'right' }}
                    >
                        {msg.message}
                    </div>
                    <div className="p-4" style={{ height: '100px' }}>
                        <img
                            src={biz_man}
                            style={{ objectFit: 'contain', height: '100%' }}
                        />
                    </div>
                </Flex>
                {/* {files_references} */}
            </>
        );
    }

    return (
        <>
            <Flex direction="row" className={style.border}>
                <div className="p-3" style={{ height: '100px' }}>
                    <img
                        src={search}
                        style={{ objectFit: 'contain', height: '100%' }}
                    />
                </div>
                <div className="flex-grow-1 p-4 align-self-center">
                    {msg.message}
                </div>
                <div className="p-4 align-self-end" style={{ width: '100px' }}>
                    {moment(msg.date).format(mFormat)}
                </div>
            </Flex>
            {/* {files_references} */}
        </>
    );
};

import { Backend } from '@assets/backend/backend';
import { DataAccessUtils } from '@assets/component/Financial/DataAccessUtils';
import { assert } from 'console';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import {
    action,
    autorun,
    computed,
    has,
    observable,
    runInAction,
    values
} from 'mobx';
import moment from 'moment';
import { FieldsSchema } from './FieldsSchema';
import _ from 'lodash';
import { OBSERVABLE } from 'mobx/dist/internal';
import { useEffect } from 'react';
import { ChatMessageData, RagAPI, RagServiceData } from './RagAPI';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';

function isString(s) {
    return typeof s === 'string' || s instanceof String;
}

interface StockInfo {
    ticker: string;
    current: number;
    eps: number;
    avg_over_year: number;
}

interface TranscriptEntry {
    highlight: string;
    source_text: string;
}
interface KPI {
    kpi: string;
    value: string;
}

interface YearlyFinance {
    cogs: string;
    ebitda: string;
    revenue: string;
    year: string;
    ebitda_margin: string;
    yoy_growth: string;
}
interface KeyPerson {
    name: string;
    position: string;
    started_job_year: string;
    bio: string;
}

interface Segment {
    revenue: string;
    service: string;
    segment: string;
    share: string;
    short_description: string;
}

interface ActiveJob {
    jobId: string;
    fileName: string;
    status: string;
    task: string;
    progress: number;
}

interface UserInfo {
    isAdmin: boolean;
}

interface ModalDisplay {
    title: string;
    transcript: TranscriptEntry;
}

class Store {
    constructor() {
        autorun(() => {
            if (!this.query) {
                return null;
            }

            //This is some CSS container magic related to width re-sizing see it in index.css
            //@container root-animations (width: 1px)
            var el = document.getElementById('rootContainer');
            el.style.width = '2px';

            const storage = getStorage();
            var file = this.query.value('pdf');

            const gsPath = getFirebase().getConfig().storageBucket;
            const pathReference = ref(storage, `gs://${gsPath}/pdfs/${file}`);

            //this.pdfFileUrl = '';

            getDownloadURL(pathReference).then(url => {
                runInAction(() => {
                    this.pdfFileUrl = url;
                });
            });
        });
    }

    @observable
    accessor uiDisplayReportId: string = '';

    @observable
    accessor reportDataDB: any = null;

    @observable
    accessor settings: any = {};

    @computed get isAdmin() {
        return this.users[this.userId]?.isAdmin;
    }
    @computed get showExportButton() {
        if (!this.isAdmin) return false;

        return this.settings?.showExportButton ?? false;
    }

    @computed get showRerunReport() {
        if (!this.isAdmin) return false;
        return this.settings?.showRerunReport ?? false;
    }

    @computed get showDeleteButton() {
        if (!this.isAdmin) return false;
        return this.settings?.showDeleteButton ?? false;
    }

    @observable
    accessor reportDataOverridesDB: any = {};

    @observable
    accessor users: { [userId: string]: UserInfo } = {};

    @observable accessor userId: string;

    @observable
    accessor jobs: any = {};

    @observable
    accessor systemStatus: any = {};

    @observable accessor activeJob: ActiveJob = null;

    @observable accessor areOveridesLoaded: boolean = false;
    //Generalize this isLoaded thing

    @observable accessor isChatLoaded: boolean = false;
    @observable accessor chatData: { [reportId: string]: ChatMessageData[] } =
        {};

    @observable accessor ragDB: any = {};
    @observable accessor isRagDbLoaded: boolean = false;

    @observable accessor fileAttachmentsDb: any = {};
    @observable accessor areFileAttachmentsLoaded: boolean = false;

    @computed get queueJobs() {
        return [];
        if (!this.jobs) {
            return [];
        }
        var activeJob = this.activeJob;

        var jobs = values(this.jobs);
        jobs = jobs.filter((job: any) => {
            return job.status == 'pending';
        });

        jobs = jobs.toSorted((a: any, b: any) => {
            return a.createdAt - b.createdAt;
        });

        return jobs;
    }

    @observable
    accessor pdfImagePage: number = -1;

    @observable accessor pdfFileUrl: string = '';

    @computed get selectedReportId() {
        return this.uiDisplayReportId;
    }

    @observable accessor lastReportLoaded: string = '';

    @computed get reportOverride() {
        const ticker = this.selectedReportId;
        var over_rides = {};
        if (
            this.reportDataOverridesDB &&
            this.reportDataOverridesDB.hasOwnProperty(ticker)
        ) {
            over_rides = this.reportDataOverridesDB[ticker];
        }
        return over_rides;
    }

    @computed get activeReportOriginal() {
        const ticker = this.selectedReportId;
        if (!this.reportDataDB) {
            return null;
        }

        if (this.reportDataDB.hasOwnProperty(ticker)) {
            if (this.reportDataDB[ticker].status == 'processing') {
                return null;
            }

            if (this.lastReportLoaded != ticker) {
                runInAction(() => {
                    this.lastReportLoaded = ticker;
                    this.pdfImagePage = -1;
                });
            }

            //Override values
            const reportName = this.selectedReportId;

            //const over_rides = System_Over_Overrides[reportName] || {};

            var over_rides = this.reportOverride;
            return _.cloneDeep(this.reportDataDB[ticker]);
        }

        return null;
    }

    @computed get isLoaded() {
        return this.reportDataDB != null && this.areOveridesLoaded;
    }

    @computed get reportChatHistory() {
        return (
            this.chatData[this.selectedReportId] ?? [
                {
                    isMe: false,
                    message: 'Hello! How can I help you today?',
                    date: new Date()
                }
            ]
        );
    }

    @computed
    get query(): DataAccessUtils {
        if (!this.activeReportOriginal) {
            return null;
        }

        if (
            !this.areOveridesLoaded ||
            !this.isRagDbLoaded ||
            !this.areFileAttachmentsLoaded ||
            !this.isChatLoaded
        ) {
            return null;
        }

        const ragData =
            (this.ragDB[this.selectedReportId] as RagServiceData) ?? null;

        return new DataAccessUtils(
            this.activeReportOriginal,
            this.reportOverride,
            new RagAPI(this.selectedReportId, ragData)
        );
    }

    createDataAccess(reportId: string) {
        const deal = this.reportDataDB[reportId]; //Fix over rides ?
        if (deal['status'] != 'completed') {
            return null;
        }
        const override = this.reportDataOverridesDB[reportId];
        return new DataAccessUtils(deal, override);
    }

    @computed
    get company_name() {
        return this.query?.value('company_name');
    }

    @computed
    get ticker() {
        return this.query?.value('ticker');
    }

    @computed get reportCreationDate() {
        if (!this.query?.value('created')) return null;

        return moment.unix(this.query?.value('created')).format('YYYY-MM-D');
    }

    @computed get errorMessageTicker() {
        if (!this.query?.value('error')) return null;
        return this.query?.value('error');
    }
    @computed get reportFiscalYear() {
        if (!this.query?.value('report_fiscal_year')) return null;

        return this.query?.value('report_fiscal_year');
    }

    @computed get companyOverview() {
        return this.query?.value('company_overview');
    }
}

type SIDE_CONTENT = 'PDF' | 'CHAT';

export enum UIDealDetailsTabs {
    DETAILS = 'DETAILS',
    COMPARE = 'COMPARE'
}

class StoreUI {
    @observable accessor currentSplitRatioDetailView = 50;
    @observable
    accessor modal: ModalDisplay = null;

    @observable accessor dealPageNameSearch: string = '';
    @observable accessor dealPageIndustrySearch: string[] = [];
    @observable accessor dealPageScoreSearch: string[] = [];

    @observable accessor pageTitle = '';

    @observable accessor sideContent: SIDE_CONTENT = 'PDF';

    @observable accessor dealViewTab: UIDealDetailsTabs =
        UIDealDetailsTabs.DETAILS;

    @observable accessor chatWaitingForResponse: boolean = false;
    switchSideContent() {
        this.sideContent = this.sideContent == 'PDF' ? 'CHAT' : 'PDF';
    }
}

const store = new Store();
const storeUI = new StoreUI();

export const useStore = () => store;
export const useStoreUI = () => storeUI;

export const useSetPageTitle = (pageTitle: string) => {
    useEffect(() => {
        storeUI.pageTitle = pageTitle;
    }, [pageTitle]);
};
export const useBackend = () => {
    return new Backend();
};
export default store;

import { Flex } from '../UI/Flex/Flex';
import loading from './loading.svg';
import Lottie from 'react-lottie';
import animData from './loading.json';
export const Loading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        play: true,
        animationData: animData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Flex
            fillCell={true}
            justify="start"
            alignItems="center"
            style={{ backgroundColor: 'white', pointerEvents: 'none' }}
            direction="column"
        >
            <div
                style={{
                    color: '#243A5C',
                    fontSize: '60px',
                    fontFamily: 'LustTextMedium',
                    marginTop: '200px',
                    zIndex: 100
                }}
            >
                Loading
            </div>

            <Lottie options={defaultOptions} height={400} width={400} />
        </Flex>
    );
};

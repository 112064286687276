type FieldType = '$' | '%' | 'year' | 'str' | 'bool'


export type SchemaField = {
	t: FieldType
	yearly?: boolean,
	array?: boolean,
	field?: string
}


// type FieldsSchemaType = {
// 	[key: string]: SchemaField;
// };

type FieldsSchemaType = {
	net_revenue_per_year: SchemaField;
	ebitda_per_year: SchemaField;
	ebitda_pro_forma_per_year: SchemaField;
	adjusted_ebitda_per_year: SchemaField;
	gross_profit_per_year: SchemaField;
	fixed_asset_acquisitions_revenue_per_year: SchemaField;
	recurring_revenue_percent_per_year: SchemaField;
	gross_retention_lost_only_per_year: SchemaField;
	net_retention_yearly: SchemaField;
	ebitda_cashflow_yearly: SchemaField;
	capex_per_year: SchemaField;
	maintenance_capex_per_year: SchemaField;
	growth_capex_per_year: SchemaField;
	total_current_assets_yearly: SchemaField;
	total_current_liabilities_yearly: SchemaField;
	historical_market_size: SchemaField;
	projected_market_size: SchemaField;

	list_years_actualized_revenue: SchemaField;
	list_years_projected_revenue: SchemaField;

	recurring_revenue_summary: SchemaField;

	company_name: SchemaField;
	company_overview: SchemaField;
	company_main_market: SchemaField;
	company_ceo: SchemaField;
	hq_location: SchemaField;
	year_founded: SchemaField;

	industry: SchemaField;
	industrySubType: SchemaField;

	ltm_net_revenue: SchemaField;
	ltm_ebitda: SchemaField;
	ltm_adjusted_ebitda: SchemaField;
	ltm_ebitda_pro_forma: SchemaField;
	subscription_revenue: SchemaField;
	recurring_revenue_stream: SchemaField;
	contractual_revenue: SchemaField;
	total_addressable_market: SchemaField;
	market_share: SchemaField;
	historical_market_growth: SchemaField;
	projected_market_growth: SchemaField;
	last_full_fiscal_year_reported: SchemaField;
};

export const FieldsSchema: FieldsSchemaType = {
	"net_revenue_per_year": { t: '$', yearly: true, field: 'revenue' },
	"ebitda_per_year": { t: '$', yearly: true, field: 'ebitda' },
	"ebitda_pro_forma_per_year": { t: '$', yearly: true, field: 'ebitda_pro_forma' },
	"adjusted_ebitda_per_year": { t: '$', yearly: true, field: 'adjusted_ebitda' },
	"gross_profit_per_year": { t: '$', yearly: true, field: 'gross_profit' },
	"fixed_asset_acquisitions_revenue_per_year": { t: '$', yearly: true, field: 'acquisition_rev' },
	"recurring_revenue_percent_per_year": { t: '$', yearly: true, field: 'net_retention_percent' },
	"gross_retention_lost_only_per_year": { t: '$', yearly: true, field: 'ebitda_cashflow' },
	"net_retention_yearly": { t: '$', yearly: true, field: 'net_retention_percent' },
	"ebitda_cashflow_yearly": { t: '$', yearly: true, field: 'ebitda_cashflow' },
	"capex_per_year": { t: '$', yearly: true, field: 'capex' },
	"maintenance_capex_per_year": { t: '$', yearly: true, field: 'maintenance_capex' },
	"growth_capex_per_year": { t: '$', yearly: true, field: 'growth_capex' },
	"total_current_assets_yearly": { t: '$', yearly: true, field: 'total_current_assets' },
	"total_current_liabilities_yearly": { t: '$', yearly: true, field: 'total_current_liabilities' },
	"historical_market_size": { t: '%', yearly: true, field: 'historic_market_size' },
	"projected_market_size": { t: '%', yearly: true, field: 'projected_market_size' },

	"list_years_actualized_revenue": { t: 'year', array: true },
	"list_years_projected_revenue": { t: 'year', array: true },

	"recurring_revenue_summary": { t: 'bool' },

	"company_name": { t: 'str' },
	"company_overview": { t: 'str' },
	"company_main_market": { t: 'str' },
	"company_ceo": { t: 'str' },
	"hq_location": { t: 'str' },
	"year_founded": { t: 'year' },

	"industry": { t: 'str' },
	"industrySubType": { t: 'str' },

	"ltm_net_revenue": { t: '$' },
	"ltm_ebitda": { t: '$' },
	"ltm_adjusted_ebitda": { t: '$' },
	"ltm_ebitda_pro_forma": { t: '$' },
	"subscription_revenue": { t: 'bool' },
	"recurring_revenue_stream": { t: 'bool' },
	"contractual_revenue": { t: 'bool' },
	"total_addressable_market": { t: '$' },
	"market_share": { t: '%' },
	"historical_market_growth": { t: '%' },
	"projected_market_growth": { t: '%' },
	"last_full_fiscal_year_reported": { t: 'year' },
};



export type DataField = keyof FieldsSchemaType;
const DataFieldEnum = Object.keys(FieldsSchema).reduce((acc, key) => {
	acc[key as keyof FieldsSchemaType] = key as keyof FieldsSchemaType;
	return acc;
}, {} as Record<keyof FieldsSchemaType, keyof FieldsSchemaType>);
export type Field = typeof DataFieldEnum; // Dynamic enum-like type


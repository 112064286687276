import { useResize } from '@assets/hooks/useResize';
import { useVirtualizer } from '@tanstack/react-virtual';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { Flex } from '../UI/Flex/Flex';
import * as style from './DealScrollVirtualized.module.less';
import { EmptyThumbnail } from './DealThumbnail/DealThumbnail';
import useMeasure from 'react-use-measure';
interface DealSearchVirtualizedProps {
    deals: React.ReactNode[];
}

export const DealSearchVirtualized = observer(
    (props: DealSearchVirtualizedProps) => {
        const thumbMinSize = 660;

        const parentRef = useRef();
        const [resizeRef, bounds] = useMeasure();

        const [elementsPerLine, setElementsPerLine] = useState(3);

        const deals = [...props.deals];
        const [totalRows, setTotalRows] = useState(
            props.deals.length / elementsPerLine
        ); //Good guess
        const rowVirtualizer = useVirtualizer({
            count: totalRows,
            getScrollElement: () => parentRef.current,
            estimateSize: i => 400 + 15,
            overscan: 2
        });

        const totalRowsNeeded = Math.ceil(deals.length / elementsPerLine);
        if (totalRows !== totalRowsNeeded) {
            setTotalRows(totalRowsNeeded);
        }

        if (bounds.width > 0) {
            const expectedElemsPerLine = Math.max(
                Math.floor(bounds.width / thumbMinSize),
                1
            );

            if (expectedElemsPerLine !== elementsPerLine) {
                setElementsPerLine(expectedElemsPerLine);
            }
        }

        const fillLine = deals.length % elementsPerLine;

        if (elementsPerLine > 1) {
            for (let i = 0; i < elementsPerLine - fillLine; i++) {
                deals.push(<EmptyThumbnail key={'empty_' + i.toString()} />);
            }
        }

        return (
            <div
                style={{
                    height: 'calc(100vh - 250px)',
                    width: 'calc(100% + 20px)'
                }}
            >
                <div
                    ref={resizeRef}
                    style={{
                        width: '100%',
                        height: '1px',
                        position: 'absolute'
                    }}
                ></div>
                <div
                    ref={parentRef}
                    style={{
                        height: `100%`,
                        width: `100%`,
                        overflow: 'auto',
                        scrollbarColor: ' #eaecf0',
                        scrollbarWidth: 'thin'
                    }}
                >
                    <div
                        style={{
                            height: `${rowVirtualizer.getTotalSize()}px`,
                            width: '100%',
                            position: 'relative'
                        }}
                    >
                        {rowVirtualizer.getVirtualItems().map(virtualRow => {
                            const dealsElements = [];
                            for (var i = 0; i < elementsPerLine; i++) {
                                dealsElements.push(
                                    deals[
                                        virtualRow.index * elementsPerLine + i
                                    ]
                                );
                            }

                            return (
                                <Flex
                                    key={virtualRow.index}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: `400px`,
                                        transform: `translateY(${virtualRow.start}px)`
                                    }}
                                >
                                    {dealsElements}
                                </Flex>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
);

import { Field, PanelColumn, PanelRow } from '@assets/component/Panel/PanelTable/PanelTable';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { FieldsSchema, SchemaField } from '@assets/model/FieldsSchema';
import store, { useStore } from '@assets/model/Store';
import { DataAccessUtils } from '../DataAccessUtils';
import { assert } from 'console';

const convertValueByType = (field: SchemaField, newValue: any) => {
	//'$' | '%' | 'year' | 'str' | 'bool'
	if (newValue === null) return null;

	switch (field.t) {
		case '$':
		case '%':
			return parseFloat(newValue);
		case 'str':
			return newValue;
		case 'bool':
			return newValue == 1 || newValue == 'true' ? true : false;
		default:
			return newValue;
	}

}

const update_override = (tickerId: string, path: string, val: any, originalValue: any) => {
	if (val === null || val == originalValue) {
		getFirebase().remove_override(
			tickerId,
			path
		);
	} else {
		getFirebase().store_override(
			tickerId,
			path,
			val.toString()
		);
	}
}

export const onEditReportField = (row: PanelRow, column: PanelColumn, newValue: any) => {

	const rowField = row.field;
	const colField = column.column;

	const schema = FieldsSchema;
	const report = store.query.activeReport;
	const f = schema[rowField.source];
	const query = useStore().query;
	const data = new DataAccessUtils(store.activeReportOriginal, {});

	let val_db = null;

	if (f.yearly ?? false) {
		val_db = data.valueByYear(rowField.source, parseInt(colField.source));
	} else {
		val_db = data.value(rowField.source);
	}

	try {
		if (colField.display === 'Source') { //Special case
			//This is source column, Update based on column not row 
			const overidePath = `${rowField.source}_page_source`;
			update_override(
				store.selectedReportId,
				overidePath,
				newValue ? newValue.toString() : null,
				val_db
			);

			return;
		}

		const convValue = convertValueByType(f, newValue);

		if (f.yearly ?? false) {
			const year = parseInt(colField.source);
			const overidePath = `${rowField.source}/${year.toString()}`;

			update_override(
				store.selectedReportId,
				overidePath,
				convValue,
				val_db
			);
		} else if (f.array ?? false) {
			//LATER
		} else {
			//value
			const overidePath = `${rowField.source}`;

			update_override(
				store.selectedReportId,
				overidePath,
				convValue,
				val_db
			);
		}
	} catch (e) {
		console.log('Error onEditReportField', e);
	}
};


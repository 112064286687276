import { Flex } from '../UI/Flex/Flex';

import { useStore, useStoreUI } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { DataAccessUtils } from '../Financial/DataAccessUtils';
import { DealProcessing } from './DealProcessing/DealProcessing';
import * as style from './DealSearchContent.module.less';
import { DealSearchHeader } from './DealSearchHeader/DealSearchHeader';
import { DealThumbnail, EmptyThumbnail } from './DealThumbnail/DealThumbnail';

import { DealMissingPassword } from './DealMissingPassword/DealMissingPassword';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useResize } from '@assets/hooks/useResize';
import { DealSearchVirtualized } from './DealScrollVirtualized';

export const DealSearchContent = observer(() => {
    const store = useStore();
    const uiStore = useStoreUI();

    useEffect(() => {
        uiStore.dealPageIndustrySearch = [];
        uiStore.dealPageNameSearch = '';
    }, []);

    const dealKeys = Object.keys(store.reportDataDB);
    //Show completed reports
    var filtered = dealKeys.filter(deal => {
        var e = store.reportDataDB[deal];
        var status = e.status;
        if (status != 'completed') {
            return false;
        }

        var dataAccess = new DataAccessUtils(
            e,
            store.reportDataOverridesDB[deal] || {}
        );

        const companyName = store.reportDataDB[deal].company_name;
        const matchCompanyName =
            companyName
                .toLowerCase()
                .includes(uiStore.dealPageNameSearch.toLowerCase()) ||
            uiStore.dealPageNameSearch == '';

        const filter = uiStore.dealPageIndustrySearch;
        var industry = dataAccess.value('industry');
        var sub_industry = dataAccess.value('industrySubType');
        var matchIndustry =
            filter.includes(industry) ||
            filter.includes(sub_industry) ||
            filter.length == 0;

        const filterScore = uiStore.dealPageScoreSearch;
        var matchScore = true;
        if (filterScore.length > 0) {
            var type = filterScore[0];
            const score = dataAccess.score();
            if (type == 'All') {
                matchScore = true;
            } else if (type == 'Strong') {
                matchScore = score > 85;
            } else if (type == 'Medium') {
                matchScore = score > 60 && score <= 85;
            } else if (type == 'Weak') {
                matchScore = score <= 60;
            }
        }

        return matchCompanyName && matchIndustry && matchScore;
    });

    //sort by creation than name
    filtered = filtered.sort((a, b) => {
        const ep1 = store.reportDataDB[a].report_creation_epoch || 0;
        const ep2 = store.reportDataDB[b].report_creation_epoch || 0;
        if (ep1 === ep2) {
            const aName = store.reportDataDB[a].company_name;
            const bName = store.reportDataDB[b].company_name;
            return aName.localeCompare(bName);
        } else {
            return ep2 - ep1;
        }
    });

    //Add pending jobs to view
    const pendingJobs = [];
    for (const key in store.jobs) {
        var e = store.jobs[key];
        if (e.status == 'pending') {
            pendingJobs.push(key);
        }
    }

    const queuedFilesInJobs = [];
    for (var jobId in store.jobs) {
        var job = store.jobs[jobId];

        if (job.status == 'pending') {
            queuedFilesInJobs.push(job.fileName.toLowerCase());
        }
    }

    var activeJob = store.activeJob;
    if (store.activeJob) {
        const job = store.activeJob;
        if (job.status == 'processing') {
            const fileName = activeJob.fileName.toLowerCase();
            queuedFilesInJobs.push(fileName);
        }
    }

    const pdfLocked = [];

    //Switch to sorting the view by the final IDs

    for (const key in store.reportDataDB) {
        var e = store.reportDataDB[key];
        var file = e['pdf'];
        if (
            e.status == 'failed' &&
            e['error'] == 'pdf_password' &&
            !queuedFilesInJobs.includes(file.toLowerCase())
        ) {
            pdfLocked.push(key);
        }
    }

    if (pendingJobs.length > 0) {
        pendingJobs.forEach((jobId: any) => {
            if (!filtered.includes(jobId)) {
                filtered.unshift(jobId);
            }
        });
    }

    if (pdfLocked.length > 0) {
        pdfLocked.forEach((jobId: any) => {
            if (!filtered.includes(jobId)) {
                filtered.unshift(jobId);
            }
        });
    }

    if (
        store.activeJob &&
        store.activeJob.status === 'processing' &&
        !filtered.includes(store.activeJob.jobId)
    ) {
        const jobs = store.jobs;
        for (const key in jobs) {
        }
        filtered.unshift(store.activeJob.jobId);
    }

    let deals = filtered.map(deal => {
        if (pdfLocked.includes(deal) && !pendingJobs.includes(deal)) {
            return <DealMissingPassword key={deal} jobId={deal} />;
        } else if (
            (store.activeJob &&
                store.activeJob.status === 'processing' &&
                deal === store.activeJob.jobId) ||
            pendingJobs.includes(deal)
        ) {
            return <DealProcessing key={deal} jobId={deal} />;
        }

        return <DealThumbnail name={deal} key={deal} />;
    });

    return (
        <>
            <Flex
                direction="column"
                className={style.container}
                justify="start"
            >
                <DealSearchHeader />
                <DealSearchVirtualized deals={deals} />
            </Flex>
        </>
    );
});

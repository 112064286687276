import { DataAccessUtils } from '@assets/component/Financial/DataAccessUtils';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { assert } from 'console';
import axios from 'axios';
import { observable } from 'mobx';
import { useStoreUI } from './Store';

export interface RagServiceData {
    status: string;
    ragDocId?: string;
    originalDocId?: string;
    //    conversationId?: string;
}

export interface ChatMessageData {
    isMe: boolean;
    message: string;
    date: Date;
}

type RagStatus = 'READY' | 'PENDING' | 'LOADING' | 'NO_DATA' | 'WAITING_INIT';

const API_POINT = 'http://localhost:7272';
export class RagAPI {
    private static accessTokenAPI: string = null; //This can be static
    private query: DataAccessUtils = null;
    public status: RagStatus = 'PENDING';

    get isAvailable() {
        return this.status === 'READY';
    }

    get ragDocId(): string {
        return this.ragData.ragDocId;
    }

    get convDocIds(): string[] {
        const ragDocIds = [this.ragDocId];

        const ragData = this.ragData as any;
        for (var key in ragData) {
            if (
                key !== 'ragDocId' &&
                key !== 'status' &&
                key != 'originalDocId' && //refactor this, ugly
                key != 'conversationId'
            ) {
                var data = ragData[key];
                ragDocIds.unshift(data.ragDocId);
            }
        }

        return ragDocIds;
    }

    constructor(
        public reportId: string,
        private ragData?: RagServiceData
    ) {
        if (!ragData) {
            this.status = 'NO_DATA';
            return;
        }
        if (ragData.status == 'completed_r2r') {
            this.status = 'WAITING_INIT';
        }
    }

    async init(query: DataAccessUtils) {
        this.query = query;
        if (this.status == 'WAITING_INIT') {
            await this.initToken();
            //await this.verifyConversationExists();
            //await this.loadMessages();
            this.status = 'READY';
        }
    }

    async initToken() {
        //Move to cloud FN / etc
        if (RagAPI.accessTokenAPI == null) {
            const loginUrl = `${API_POINT}/v3/users/login`;
            const loginResp = await axios.post(
                loginUrl,
                {
                    username: 'admin@example.com',
                    password: 'change_me_immediately'
                },
                {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded'
                    }
                }
            );

            const bearerToken = loginResp.data.results['access_token']['token'];
            RagAPI.accessTokenAPI = bearerToken;
        }
    }

    async getRequest(apiPath: string) {
        const resp = await axios.get(`${API_POINT}${apiPath}`, {
            headers: {
                Authorization: 'Bearer ' + RagAPI.accessTokenAPI
            }
        });
        return resp;
    }

    async postRequest(apiPath: string, data: any) {
        const resp = await axios.post(`${API_POINT}${apiPath}`, data, {
            headers: {
                Authorization: 'Bearer ' + RagAPI.accessTokenAPI
            }
        });
        return resp;
    }

    // async verifyConversationExists() {
    //     if (!this.ragData.conversationId) {
    //         const resp = await this.postRequest('/v3/conversations', {});
    //         const convId = resp.data.results.id;
    //         this.ragData.conversationId = convId;
    //         //Add first message to conv to give context
    //         const companyName = this.query.value('company_name');
    //         const overview = this.query.value('company_overview');
    //         const firstMessage = `You are an investment analyst. Please provide useful answers. This is a conversation about the company called ${companyName} - This is their overview: ${overview}. `;
    //         await this.addFirstMessageContext(firstMessage);

    //         const fb = getFirebase();
    //         fb.rag_update_conversation_id(this.reportId, convId);
    //     }
    // }

    // async loadMessages() {
    //     assert(this.ragData.conversationId, 'No conversation ID');
    //     const convId = this.ragData.conversationId;
    //     const resp = await this.getRequest(`/v3/conversations/${convId}`);
    //     const messages = resp.data.results as [];
    //     this.chatHistory.length = 0;
    //     this.chatHistory.push({
    //         isMe: false,
    //         message: 'Hello! How can I help you today?',
    //         date: new Date()
    //     });
    //     messages.forEach((e: any, index: number) => {
    //         if (index == 0) {
    //             return; //This is the context message user doesn't see it
    //         }

    //         var msg = e['message'];

    //         const isMe = this.chatHistory.push({
    //             isMe: msg.role == 'user',
    //             message: msg.content,
    //             date: new Date()
    //         });
    //     });
    // }

    // async addFirstMessageContext(message: string) {
    //     //https://api.example.com/v3/conversations/:id/messages
    //     assert(this.ragData.conversationId, 'No conversation ID');
    //     const convId = this.ragData.conversationId;
    //     const resp = await this.postRequest(
    //         `/v3/conversations/${convId}/messages`,
    //         {
    //             role: 'system',
    //             content: message
    //         }
    //     );
    // }

    async sendMessage(question: string, context: string) {
        const query = this.query;
        const storeUI = useStoreUI();
        const fb = getFirebase();

        storeUI.chatWaitingForResponse = true;
        const q = context + '\r\n\r\n NEW QUESTION:' + question;

        try {
            fb.chatbot_add_message(this.reportId, question, true);

            var resp = await this.postRequest('/v3/retrieval/rag', {
                query: q,
                //task_prompt_override: prompt,
                search_settings: {
                    search_mode: 'advanced',
                    filters: {
                        document_id: {
                            $in: this.convDocIds
                        }
                    },
                    graph_settings: {
                        enabled: false
                    }
                }
            });

            const textResp =
                resp.data.results.completion.choices[0].message['content'];
            fb.chatbot_add_message(this.reportId, textResp, false);
            storeUI.chatWaitingForResponse = false;
        } catch (e) {
            storeUI.chatWaitingForResponse = false;
        }
    }
}

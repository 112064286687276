import { Center } from '@assets/component/Center/Center';
import {
    RowEditor,
    RowEditorProps
} from '@assets/component/Financial/PanelCompanyYearly/PanelElements/RowEditor/RowEditor';
import store from '@assets/model/Store';
import classNames from 'classnames';
import { observable, toJS } from 'mobx';
import { ReactElement, useEffect, useState } from 'react';
import * as style from './PanelTable.module.less';
export type Field = {
    source: string;
    display: string;
    description?: string;
};

export type PanelColumn = {
    column: Field;
    element?: (row: PanelRow, col: PanelColumn, data: any) => ReactElement;
    editor?: (api: RowEditorProps) => ReactElement;
    header?: (col: PanelColumn) => ReactElement;
    overrideValue?: (field: Field, column: Field) => any;
    dontUseRowFormatting?: boolean;
};

export type PanelRow = {
    field: Field;
    label?: (field: Field) => ReactElement;
    element?: (row: PanelRow, col: PanelColumn, data: any) => ReactElement;
    editor?: (api: RowEditorProps) => ReactElement;
    formatter?: (data: any) => any;
    value: (field: Field, column: Field) => any;
    editable?: boolean;
    dontShowSources?: boolean;
};

interface IPanelTableProps {
    topLeftCellText?: string;
    noHeaderRow?: boolean;
    columns: PanelColumn[];
    rows: PanelRow[];
    disable_round_edges?: boolean;
    className?: string;
    onEditField?: (row: PanelRow, column: PanelColumn, newValue: any) => void;
}

type CellId = {
    row: PanelRow;
    col: PanelColumn;
    rowIndex: number;
    colIndex: number;
};

export class EditTableStore {
    @observable accessor dealData: any = null;
    @observable accessor editCellValue: any = null;
    @observable accessor editCellId: CellId | null = null;
}

export const PanelTable = (props: IPanelTableProps) => {
    const [editValueStore] = useState(new EditTableStore());
    const [editCellRefresh, setEditCellRefreshUI] = useState<CellId | null>(
        null
    );

    const skipHeadeRow = props.noHeaderRow === true;
    function onKeyDown(e: KeyboardEvent) {
        if (editValueStore.editCellId == null) return;

        if (e.key === 'Tab' || e.key == 'ArrowUp' || e.key == 'ArrowDown') {
            e.preventDefault();

            saveCurrentEdit();
            const columnsInRow = props.columns.length;

            const editCellId = editValueStore.editCellId;
            var nextColumnIndex = editCellId.colIndex;
            var nextRowIndex = editCellId.rowIndex;

            if (e.shiftKey) {
                nextColumnIndex -= 1;
                if (nextColumnIndex < 0) {
                    nextColumnIndex = 0;
                }
            } else if (e.key == 'ArrowUp') {
                for (let i = 0; i < rows.length; i++) {
                    nextRowIndex -= 1;

                    if (nextRowIndex <= -1) {
                        return;
                    }

                    if (props.rows[nextRowIndex].editable) {
                        break;
                    }
                }
            } else if (e.key == 'ArrowDown') {
                for (let i = 0; i < rows.length; i++) {
                    nextRowIndex += 1;
                    if (nextRowIndex >= props.rows.length) {
                        return;
                    }
                    if (props.rows[nextRowIndex].editable) {
                        break;
                    }
                }
            } else if (e.key == 'Tab') {
                nextColumnIndex += 1;
                if (nextColumnIndex >= columnsInRow) {
                    nextColumnIndex -= 1;
                }
            } else if (e.key == 'Enter') {
                setCellEditMode(null);
            }

            var newRow = props.rows[nextRowIndex];
            var newCol = props.columns[nextColumnIndex];
            setCellEditMode({
                row: newRow,
                col: newCol,
                rowIndex: nextRowIndex,
                colIndex: nextColumnIndex
            });
        } else if (e.key === 'Escape') {
            setCellEditMode(null);
        } else if (e.key === 'Delete') {
            const editCellId = editValueStore.editCellId;
            props.onEditField(editCellId.row, editCellId.col, null);
            setCellEditMode(null);
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [editValueStore.editCellId, editValueStore.editCellValue]);

    const setCellEditMode = (cellId: CellId | null) => {
        editValueStore.editCellId = cellId;
        setEditCellRefreshUI(editValueStore.editCellId);

        if (cellId == null) {
            setValueForEditCell('SHOULD_NOT_SEE');
            return;
        }

        editValueStore.dealData = toJS(store.query.activeReport);
        const valFn = cellId.col.overrideValue || cellId.row.value;
        let newEditValue = valFn(cellId.row.field, cellId.col.column);
        setValueForEditCell(newEditValue);
    };

    const saveCurrentEdit = () => {
        const val = editValueStore.editCellValue;
        const editCellId = editValueStore.editCellId;

        if (props.onEditField) {
            props.onEditField(editCellId.row, editCellId.col, val);
        }
    };

    const setValueForEditCell = (value: any) => {
        if (
            value.toString() == 'NaN' ||
            value.toString() == 'N\\A' ||
            value.toString() == 'N/A'
        ) {
            value = '';
        }

        editValueStore.editCellValue = value;
    };

    const disableRoundEdges = props.disable_round_edges === true;

    const elementsToGridRow = (
        row: number,
        elements: ReactElement[],
        classesToAdd: string[] = [],
        rowPosition: 'start' | 'end' | 'none' = 'none'
    ) => {
        return elements.map((el, index) => {
            var cls = classNames(
                style.grid_item,
                style[`grid_row_${row}`],
                style[`grid_col_${index + 1}`],
                `grid_row_${row}`,
                `grid_col_${index + 1}`,
                { even_row: row % 2 === 0 },
                { odd_row: row % 2 === 1 },
                ...classesToAdd,
                style.grid__item,
                { [style.grid_col_start]: index === 0 },
                { [style.grid_col_end]: index === elements.length - 1 },
                { [style.grid_row_first]: rowPosition === 'start' },
                { [style.grid_row_last]: rowPosition === 'end' },
                { [style.disable_round_edges]: disableRoundEdges }
            );

            return (
                <div key={'Row_' + row + '_' + index} className={cls}>
                    {el}
                </div>
            );
        });
    };

    const rows = [];

    if (!skipHeadeRow) {
        const headerLabels = [
            <div>{props.topLeftCellText}</div>,
            ...props.columns.map(col => {
                const el = col.header ? (
                    col.header(col)
                ) : (
                    <div>{col.column.display}</div>
                );

                return <div key={col.column.source}>{el}</div>;
            })
        ];
        rows.push(elementsToGridRow(1, headerLabels, [style.headerRow]));
    }

    props.rows.forEach((row, rowIndex) => {
        const newRow = [
            <Center justifyContent="start">
                {row.label ? row.label(row.field) : row.field.display}
            </Center>
        ];

        props.columns.forEach((col, colIndex) => {
            const isRowEditable = row.editable;
            const columnsInRow = props.columns.length;
            const cellId = { row, col, rowIndex, colIndex };
            const editCellId = editValueStore.editCellId;
            const editMode =
                editCellId &&
                editCellId.rowIndex == rowIndex &&
                editCellId.colIndex == colIndex;

            let val = '-';
            let val_noformatting = '-';
            const valFn = col.overrideValue || row.value;
            try {
                val = valFn(row.field, col.column);
                val_noformatting = val;

                if (!editMode) {
                    if (row.formatter && col.dontUseRowFormatting !== true) {
                        val = row.formatter(val);
                    }
                }
            } catch (e) {
                console.log('Error in PanelTable', e);
                val = '-';
            }

            const createEl = col.element || row.element;
            const createEditor = col.editor || row.editor || RowEditor.textbox;

            const onEditApi = (value: string, save: boolean) => {
                setValueForEditCell(value);
                if (save) {
                    saveCurrentEdit();
                    setCellEditMode(null);
                }
            };
            const editApi: RowEditorProps = {
                row,
                col,
                val: editValueStore,
                onEdit: onEditApi
            };
            const newEl = (
                <Center
                    key={colIndex}
                    justifyContent="start"
                    onDoubleClick={() => {
                        if (props.onEditField && row.editable) {
                            setCellEditMode({ row, col, rowIndex, colIndex });
                        }
                    }}
                >
                    {editMode ? createEditor(editApi) : createEl(row, col, val)}
                </Center>
            );
            newRow.push(newEl);
        });

        const rowPosition =
            rowIndex === 0
                ? 'start'
                : rowIndex === props.rows.length - 1
                  ? 'end'
                  : 'none';
        rows.push(elementsToGridRow(rows.length + 1, newRow, [], rowPosition));
    });
    // props.columns.forEach((col, index) => {
    //     const v = col.value(col.field, col.column, props.data);

    //     const row = dataToRow(
    //         index + 2,
    //         data.map((d: any) => d.value)
    //     );
    //     rows.push(row);
    // });
    var cls = classNames(style.gridContainer, props.className);
    return <div className={cls}>{rows}</div>;
};

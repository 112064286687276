import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { DealScore } from '../DealDetails/DealScore/DealScore';
import { DataAccessUtils } from '../Financial/DataAccessUtils';
import {
    formatDollar,
    formatPercent,
    noFormat
} from '../Financial/PanelCompanyYearly/PanelElements/Formatters';
import { RowLabel } from '../Financial/PanelCompanyYearly/PanelElements/RowLable/RowLable';
import { RowValue } from '../Financial/PanelCompanyYearly/PanelElements/RowValue/RowValue';
import {
    PanelColumn,
    PanelRow,
    PanelTable
} from '../Panel/PanelTable/PanelTable';
import {
    ScoreLine,
    ScoreEntry as ScoreLineEntry
} from '../ScoreLine/ScoreLine';

import * as style from './DealCompare.module.less';
import { Flex } from '../UI/Flex/Flex';
import { PanelTitle } from '../Panel/PanelTitle/PanelTitle';
import { ScrollPanel } from '../Panel/ScrollPanel/ScrollPanel';
import { assert } from 'console';
import classNames from 'classnames';
import { useNavUtils } from '@assets/utils/NavUtils';

export const DealCompare = observer(() => {
    const store = useStore();
    const navUtils = useNavUtils();

    if (store.selectedReportId == '' || store.query == null) return null;
    const q = store.query;

    const findCompaniesToCompare = () => {
        const companies = Object.keys(store.reportDataDB).filter(
            reportId => store.reportDataDB[reportId].status == 'completed'
        );

        const fullMatch = [];
        const partialMatch = [];
        const noMatch = [];

        const industry = q.value('industry');
        const subIndustry = q.value('industrySubType');

        companies.forEach(reportId => {
            const compA = store.createDataAccess(reportId);

            const otherIndustry = compA.value('industry');
            const otherSub = compA.value('industrySubType');

            if (store.selectedReportId == reportId) return;
            if (industry == otherIndustry && subIndustry == otherSub) {
                fullMatch.push(reportId);
            } else if (industry == otherIndustry) {
                partialMatch.push(reportId);
            } else {
                noMatch.push(reportId);
            }
        });

        var allCompanies = fullMatch.concat(partialMatch).concat(noMatch);
        return allCompanies.slice(0, 3);
    };

    const companies = [store.selectedReportId, ...findCompaniesToCompare()];

    const dataAccess = { string: DataAccessUtils };
    companies.forEach(company => {
        const query = store.createDataAccess(company);
        dataAccess[company] = query;
    });

    const fieldsNameLookup = {
        organic_cagr_4_years: '4-Year Historical Organic Revenue CAGR',
        projected_organic_cagr_4_years: '4-Year Projected Organic Revenue CAGR',
        projected_organic_cagr_4_years_minus_tam_growth:
            'Relative Growth Rate (Company Growth less Market Growth)',
        gross_retention: 'Gross Retention Rate',
        net_retention: 'Net Retention Rate',
        recurring_revenue_summary: 'Contractual Revenue',
        ebitda: 'EBITDA',
        ebitda_margin: 'EBITDA Margin',
        ebitda_margin_trend: 'EBITDA Margin versus Two Years Ago',
        ebitda_magnatitude_adjustment: '% of Adjusted EBITDA from Adjustments',
        total_addressable_market: 'Total Addressable Market Size',
        historical_market_growth: 'Historical Market Growth Rate',
        projected_market_growth: 'Projected Market Growth Rate',
        nwc_as_percent_of_revenue: 'New Working Capital, as a % of Revenue',
        free_cash_flow_conversion: 'Free Cash Flow Conversion',
        total_capex_by_rev: 'CapEx, as a % of Revenue'
    };

    const getElement = (fieldName: string) => {
        if (fieldName == 'recurring_revenue_summary') {
            return RowValue.boolean;
        } else if (formatPercentFields.includes(fieldName)) {
            return RowValue.percent;
        } else {
            return RowValue.value;
        }
    };

    const getLabel = (fieldName: string) => {
        if (formatPercentFields.includes(fieldName)) {
            return RowLabel.percent_label;
        } else {
            return RowLabel.label;
        }
    };
    const getFormatter = (fieldName: string) => {
        if (formatPercentFields.includes(fieldName)) {
            return formatPercent;
        } else if (fieldName == 'recurring_revenue_summary') {
            return noFormat;
        } else {
            return formatDollar;
        }
    };
    const formatPercentFields = [
        'organic_cagr_4_years',
        'projected_organic_cagr_4_years',
        'projected_organic_cagr_4_years_minus_tam_growth',
        'gross_retention',
        'net_retention',
        'nwc_as_percent_of_revenue',
        'free_cash_flow_conversion',
        'total_capex_by_rev',
        'historical_market_growth',
        'projected_market_growth',
        'ebitda_margin',
        'ebitda_margin_trend',
        'ebitda_magnatitude_adjustment'
    ];

    const revenueGroup = {
        name: 'Revenue Performance',
        fields: [
            'organic_cagr_4_years',
            'projected_organic_cagr_4_years',
            'projected_organic_cagr_4_years_minus_tam_growth',
            'gross_retention',
            'net_retention',
            'recurring_revenue_summary'
        ]
    };

    const ebitdaGroup = {
        name: 'EBITDA Performance',
        fields: [
            'ebitda',
            'ebitda_margin',
            'ebitda_margin_trend',
            'ebitda_magnatitude_adjustment'
        ]
    };

    const marketGroup = {
        name: 'Market Size & Performance',
        fields: [
            'total_addressable_market',
            'historical_market_growth',
            'projected_market_growth'
        ]
    };

    const fcfGroup = {
        name: 'FCF Profile',
        fields: [
            'nwc_as_percent_of_revenue',
            'free_cash_flow_conversion',
            'total_capex_by_rev'
        ]
    };
    const fieldsGroup = [revenueGroup, ebitdaGroup, marketGroup, fcfGroup];

    const genColumn = (reportId: string, disableHeaderLogo?: boolean) => {
        return {
            column: {
                source: reportId,
                display: null
            },
            header: (col: PanelColumn) => {
                if (disableHeaderLogo) {
                    return null;
                }

                const q = getQ(col.column.source);
                const name = q.value('company_name');
                return (
                    <Flex
                        className={style.companyHeaderElement}
                        direction="column"
                        alignItems="center"
                        justify="around"
                    >
                        <a href="#" style={{ textDecoration: 'none' }}>
                            <div className={style.companyNameLabel}>{name}</div>
                        </a>
                        <DealScore score={q.score()} />
                    </Flex>
                );
            }
        };
    };
    const columns: PanelColumn[] = companies.map(reportId => {
        return genColumn(reportId);
    });

    const getQ = (reportId: string) => {
        return dataAccess[reportId] as DataAccessUtils;
    };

    const fieldsToRows = (fields: string[]) => {
        return fields.map(fieldName => {
            assert(
                fieldsNameLookup[fieldName] != null,
                `Field ${fieldName} not found in fieldsNameLookup`
            );
            return {
                field: {
                    source: null,
                    display: fieldsNameLookup[fieldName]
                },
                value: (_field, column) => {
                    const q = getQ(column.source);
                    return q.get_extended_value(fieldName);
                },

                label: getLabel(fieldName),
                formatter: getFormatter(fieldName),
                element: getElement(fieldName)
            };
        });
    };

    const groupTables = fieldsGroup.map((group, i) => {
        const groupRows = fieldsToRows(group.fields);
        return (
            <>
                <PanelTitle title={group.name} className={style.panelTitle} />
                <PanelTable
                    columns={columns}
                    rows={groupRows}
                    disable_round_edges={true}
                    className={style.panelTableStyle}
                    noHeaderRow={true}
                />
            </>
        );
    });

    const cls = classNames(style.panelTableStyle, style.panelTableCompanies);
    const groupTablesWithHeader = [
        <PanelTable
            columns={columns}
            rows={[]}
            disable_round_edges={true}
            className={cls}
        />,
        ...groupTables
    ];

    const rows: PanelRow[] = [
        // {
        //     field: {
        //         source: null,
        //         display: 'Score'
        //     },
        //     value: (_field, column) => {
        //         return 'Test';
        //     },

        //     label: RowLabel.label,
        //     element: (_field, column) => {
        //         var q = getQ(column.column.source);
        //         return <DealScore score={q.q.score()} />;
        //     }
        // },
        {
            field: {
                source: 'Revenue',
                display: 'Revenue (USD)'
            },
            value: (_field, column) => {
                const q = dataAccess[column.source];
                const year = q.actualYear();
                return q.valueByYear('net_revenue_per_year', year);
            },

            label: RowLabel.label,
            formatter: formatDollar,
            element: RowValue.value
        },
        {
            field: {
                source: 'EBITDA',
                display: 'EBITDA (USD)'
            },
            value: (_field, column) => {
                const q = dataAccess[column.source] as DataAccessUtils;
                const year = q.actualYear();

                return q.valueByYear('ebitda_per_year', year);
            },

            label: RowLabel.label,
            formatter: formatDollar,
            element: RowValue.value
        },
        {
            field: {
                source: 'industry',
                display: 'Industry'
            },
            value: (_field, column) => {
                const q = dataAccess[column.source];
                return q.value('industry');
            },

            label: RowLabel.label,

            element: RowValue.value
        },
        {
            field: {
                source: 'subIndustry',
                display: 'SubIndustry'
            },
            value: (_field, column) => {
                const q = dataAccess[column.source];
                return q.value('industrySubType');
            },

            label: RowLabel.label,

            element: RowValue.value
        }
    ];

    const entries: ScoreLineEntry[] = [
        { reportId: 'test', value: 70 },
        { reportId: 'test1', value: 50 },
        { reportId: 'test2', value: 10 }
    ];

    return (
        <>
            <ScrollPanel>{groupTablesWithHeader}</ScrollPanel>

            {/* <ScoreLine entries={entries} range={{ min: 0, max: 100 }} /> */}
        </>
    );
});

import { ChatMessage } from '@assets/component/ChatMessage/ChatMessage';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { ChatMessageData } from '@assets/model/RagAPI';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import * as style from './ChatContainer.module.less';
interface ChatContainerProps {
    chatId: string;
}

export const ChatContainer = observer((p: ChatContainerProps) => {
    //use id to fetch chat
    const messageData: ChatMessageData[] = useStore().reportChatHistory;

    const keys = Object.keys(messageData).sort();

    const reportId = useStore().selectedReportId;
    const messages = keys.map(msgId => {
        const msg = messageData[msgId];
        return <ChatMessage msg={msg} key={msgId} />;
    });

    return (
        <Flex
            direction="column"
            justify="end"
            className={style.messagesContainer}
        >
            {messages}
        </Flex>
    );
});

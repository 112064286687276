import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';

import { DealCompare } from '@assets/component/DealCompare/DealCompare';
import { DealDetails } from '@assets/component/DealDetails/DealDetails';
import { Header } from '@assets/component/Header/Header';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { UIDealDetailsTabs, useStore, useStoreUI } from '@assets/model/Store';
import { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { i18n } from '../model/Translation';
import * as style from './DealView.module.less';
import { DealDetailsHeader } from '@assets/component/DealDetails/DealDetailsHeader/DealDetailsHeader';
require('./DealView.css');

const { t } = i18n;

const DealView = observer(() => {
    const { id } = useParams();
    const store = useStore();
    const uiStore = useStoreUI();

    useEffect(() => {
        if (id) {
            store.uiDisplayReportId = id;
        }
    }, [id]);

    return (
        <>
            {/* <ModalContainer /> */}
            <div
                className={'d-flex justify-content-center'}
                style={{ height: '100%' }}
            >
                <Flex direction="column" className={'h-100 w-100'}>
                    <Header
                        disableTicker={true}
                        showDealDbLink={true}
                        showScore={true}
                        showShadow={false}
                    />
                    <div className={style.tabsShadow}></div>
                    <Tabs
                        activeKey={uiStore.dealViewTab}
                        variant="underline"
                        className={'dealViewTabs'}
                        onSelect={k =>
                            (uiStore.dealViewTab = k as UIDealDetailsTabs)
                        }
                    >
                        <Tab
                            eventKey={UIDealDetailsTabs.DETAILS}
                            title="Deal Details"
                            className={style.container}
                        >
                            <DealDetails />
                        </Tab>
                        <Tab
                            eventKey={UIDealDetailsTabs.COMPARE}
                            title="Benchmark & Comps"
                            className={style.container}
                        >
                            <DealCompare />
                        </Tab>
                    </Tabs>
                    <div className={style.container}></div>
                </Flex>
            </div>
        </>
    );
});

export default withRouter(DealView);

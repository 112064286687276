import {
    getDownloadURL,
    getStorage,
    ref,
    updateMetadata,
    uploadBytesResumable
} from 'firebase/storage';
import { getFirebase } from './FirebaseAdapater';

const STORAGE_PATH_REPORTS =
    'gs://' + getFirebase().getConfig().storageBucket + '/pdfs';
const STORAGE_PATH_ATTACHMENTS =
    'gs://' + getFirebase().getConfig().storageBucket + '/attachments';

class StorageAdapater {
    async check_if_report_exists(fileName: string) {
        const storage = getStorage();
        const docRef = ref(storage, `${STORAGE_PATH_REPORTS}/${fileName}`);

        try {
            await getDownloadURL(docRef);
            return true;
        } catch (error) {
            return false;
        }
    }

    async uploadDealAttachment(
        dealId: string,
        file: File,
        onProgress?: (progress: number) => void
    ) {
        const fb = getFirebase();
        const userId = fb.userId;
        const userEmail = fb.user.email;

        const storage = getStorage();
        const filePath = `${STORAGE_PATH_ATTACHMENTS}/${dealId}/${file.name}`;
        const storageRef = ref(storage, filePath);
        const upload_task = uploadBytesResumable(storageRef, file);

        upload_task.on(
            'state_changed',
            snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (onProgress) {
                    onProgress(progress);
                }
            },
            error => {
                return null;
            },
            () => {}
        );

        const metadata = {
            customMetadata: {
                userId,
                userEmail
            }
        };
        var snapshot = await upload_task;
        await updateMetadata(storageRef, metadata);
        return filePath;
    }

    async uploadReportPdf(file: File, onProgress?: (progress: number) => void) {
        const fb = getFirebase();
        const userId = fb.userId;
        const userEmail = fb.user.email;

        const storage = getStorage();
        const storageRef = ref(storage, `${STORAGE_PATH_REPORTS}/${file.name}`);
        const upload_task = uploadBytesResumable(storageRef, file);

        upload_task.on(
            'state_changed',
            snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (onProgress) {
                    onProgress(progress);
                }
            },
            error => {
                return false;
            },
            () => {}
        );

        const metadata = {
            customMetadata: {
                userId,
                userEmail
            }
        };
        var snapshot = await upload_task;
        await updateMetadata(storageRef, metadata);
        return true;
    }
}

const mStorageService = new StorageAdapater();
export function getStorageAdapater() {
    return mStorageService;
}

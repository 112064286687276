import { DealScore } from '@assets/component/DealDetails/DealScore/DealScore';
import { DataAccessUtils } from '@assets/component/Financial/DataAccessUtils';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { formatDollarStr } from '@assets/utils/Formatting';
import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import * as style from './DealThumbnail.module.less';
import { useState } from 'react';
import Lottie from 'react-lottie';
import animData from './thumb_loading.json';
interface IDealThumbnailProps {
    name: string;
}

export const EmptyThumbnail = () => {
    return <div className={style.thumb} style={{ visibility: 'hidden' }}></div>;
};

export const DealThumbnail = observer((p: IDealThumbnailProps) => {
    const store = useStore();
    const navUtils = useNavUtils();
    const [isImgLoaded, setIsImgLoaded] = useState(0);

    const defaultOptionsLottie = {
        loop: true,
        autoplay: true,
        play: true,
        animationData: animData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const deal = store.reportDataDB[p.name]; //Fix over rides ?
    const override = store.reportDataOverridesDB[p.name];

    const companyName = deal.company_name;

    const query = new DataAccessUtils(deal, override);
    const year = query.actualYear();
    const rev = query.valueByYear('net_revenue_per_year', year);
    const ebitda = query.valueByYear('adjusted_ebitda_per_year', year);
    const companyDesc = query.value('company_overview_short');
    const industry = query.value('industry');
    const sub_industry = query.value('industrySubType');
    const thumb = query.value('thumb');
    const metaFields = [
        { label: 'Revenue ($M)', value: formatDollarStr(rev.toString()) },
        {
            label: 'Adj. EBITDA ($M)',
            value: formatDollarStr(ebitda.toString())
        },
        { label: 'CEO', value: query.value('company_ceo') },
        { label: 'Founded', value: query.value('year_founded') }
    ];

    return (
        <div
            className={style.thumb}
            onClick={() => {
                navUtils.toTicker(p.name);
            }}
        >
            <Flex direction="column">
                <div className={style.title}>{companyName}</div>
                <div className={style.industry}>{industry}</div>
                <div className={style.divider}></div>
                <DealScore score={query.score()} className={style.score} />
                <Flex justify="between">
                    <div className={style.overview}>{companyDesc}</div>
                    <div className={style.imageThumbnailContainer}>
                        {isImgLoaded == 0 && (
                            <div className={style.imageThumbLoading}>
                                <Lottie
                                    options={defaultOptionsLottie}
                                    height={200 * 0.75}
                                    width={200}
                                />
                            </div>
                        )}

                        <img
                            src={thumb}
                            className={style.imageThumbnail}
                            style={{ opacity: isImgLoaded }}
                            onLoad={() => setIsImgLoaded(1)}
                        />
                    </div>
                </Flex>
                <Flex
                    style={{
                        position: 'absolute',
                        bottom: '30px',
                        width: '100%',
                        padding: '0 20px 0 0px',
                        maxHeight: '50px'
                    }}
                    justify="between"
                >
                    {metaFields.map((field, index) => {
                        return (
                            <div
                                key={field.label}
                                className={style.fieldContainer}
                            >
                                <div className={style.fieldTitle}>
                                    {field.label}
                                </div>
                                <div className={style.fieldValue}>
                                    {field.value}
                                </div>
                            </div>
                        );
                    })}
                </Flex>
            </Flex>
            {/* {companyName} {industry} {sub_industry} <br /> Rev:{rev} EBITDA:
            {ebitda} */}
        </div>
    );
});

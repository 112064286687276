import classNames from 'classnames';
import * as style from './PanelTitle.module.less';
import { Center } from '@assets/component/Center/Center';
export const PanelTitle = (props: { title: string; className?: string }) => {
    const cls = classNames(style.panelRowTitle, props.className);
    return (
        <Center className={cls}>
            <div className={style.panelRowTitle}>{props.title}</div>
        </Center>
    );
};
